/* eslint-disable @typescript-eslint/no-explicit-any */

export class WindowLocalStorage {
  private inMemoryStorage: { [key: string]: any } = {};

  private isSupported: boolean;

  constructor(private storage: Storage) {
    this.isSupported = this.checkIsSupported();
    console.log('[STORAGE] Supported', this.isSupported);
  }

  async get<T = unknown>(name: string): Promise<T | undefined> {
    if (this.isSupported) {
      const response = this.storage.getItem(name);
      const value = response ? JSON.parse(response) : undefined;
      console.log('[STORAGE] Getting key', name, response, value);
      return value;
    }

    if (name in this.inMemoryStorage) {
      return this.inMemoryStorage[name];
    }

    return undefined;
  }

  async set(name: string, value: unknown): Promise<void> {
    if (this.isSupported) {
      this.storage.setItem(name, JSON.stringify(value));
      return;
    }

    this.inMemoryStorage[name] = value;
  }

  async remove(name: string): Promise<void> {
    if (this.isSupported) {
      this.storage.removeItem(name);
      return;
    }

    delete this.inMemoryStorage[name];
  }

  private checkIsSupported() {
    try {
      const testKey = '__UNUSED_KEY__' + Math.random().toString();
      this.storage.setItem(testKey, testKey);
      const got = this.storage.getItem(testKey);
      this.storage.removeItem(testKey);
      return got === testKey;
    } catch (e) {
      return false;
    }
  }
}

export class InMemoryStorage {
  constructor(private store: { [key: string]: any } = {}) { }

  getItem(name: string): string | null {
    if (name in this.store) {
      return this.store[name];
    }

    return null;
  }

  setItem(name: string, value: unknown): void {
    this.store[name] = value;
  }

  removeItem(name: string): void {
    delete this.store[name];
  }

  get length(): number {
    return Object.keys(this.store).length;
  }

  clear(): void {
    this.store = {};
  }

  key(index: number): string | null {
    const keys = Object.keys(this.store);
    if (index >= keys.length) {
      return null;
    }

    return this.getItem(keys[index]);
  }
}

export function getAvailableStorage() {
  try {
    if ('localStorage' in window) {
      return window.localStorage;
    }
  } catch (e) {
    console.log('[STORAGE] Local storage is not supported');
  }

  try {
    if ('sessionStorage' in window) {
      return window.sessionStorage;
    }
  } catch (e) {
    console.log('[STORAGE] Session storage is not supported');
  }

  return new InMemoryStorage({});
}

/* eslint-enable @typescript-eslint/no-explicit-any */
