import { Global, css } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={css`
      // Main Titles
      @font-face {
        font-family: 'ClashGrotesk';
        font-display: swap;
        src: url('/fonts/ClashGrotesk-Semibold.otf') format('opentype');
      }
    `}
  />
);

export default Fonts;
