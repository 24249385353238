export interface AnalyticsEvent {
  name: string;
  [key: string]: string;
}

export async function event({ name, ...data }: AnalyticsEvent) {
  console.debug('[EVENT] DL', { name, ...data });

  if (typeof window !== 'undefined' && 'clarity' in window && typeof window.clarity === 'function') {
    // Add the given property values to the event name.
    // For example, an invalid 'manual-login' try will looks like:
    //
    //   main-login:invalid
    //
    const values = Object.values(data);
    let clarityEventName = name;
    if (values.length > 0) {
      clarityEventName = `${clarityEventName}:${values.join(':')}`;
    }

    Promise.resolve(window.clarity('event', name))
      .then((result: unknown) => {
        console.debug('Analytics event result:', { name: clarityEventName, result });
      })
      .catch((e: unknown) => {
        console.error('Error while emitting a custom event:', e);
      });
  }

  if (typeof window !== 'undefined' && 'dataLayer' in window && !!window.dataLayer && Array.isArray(window.dataLayer)) {
    window.dataLayer.push({
      event: name,
      ...data,
    });
  }
}
