import * as React from 'react';
import { useExchangeOpportunity } from '@hks/opportunity';
import { getErrorFromExtension } from '@lib/errors';

const key = 'qrcode:pending:v1';

export interface Scan {
  code: string;
  id: string;
  url: string;
}

export function storeQrCode(code: Scan) {
  localStorage.setItem(key, JSON.stringify(code));
}

export function pendingQrCode(): Scan | null {
  const raw = localStorage.getItem(key);
  if (!raw) {
    return null;
  }

  return JSON.parse(raw);
}

export interface ScanPendingOptions {
  captcha: string;
  onExchanged: () => unknown;
  onError: (err: { message: string }) => unknown;
}

export function useExchangePendingCode(options: ScanPendingOptions) {
  const [code, setCode] = React.useState<string>('');
  const [checked, setChecked] = React.useState(false);
  const [exchange, result] = useExchangeOpportunity({
    onCompleted() {
      options.onExchanged();
    },

    onError(error) {
      let message = error.message;
      const captchaErrorMessage = getErrorFromExtension(error?.graphQLErrors, 'input.captcha');
      if (captchaErrorMessage) {
        message = captchaErrorMessage;
      }

      options.onError({ message });
    },
  });

  React.useEffect(() => {
    const pending = pendingQrCode();
    const codeToExchange = pending?.code.replace('-', '').toUpperCase();
    setCode(codeToExchange || '');

    if (!options.captcha) {
      return;
    }

    setChecked(true);

    if (!codeToExchange || !pending) {
      return;
    }

    localStorage.removeItem(key);

    exchange({
      variables: {
        input: {
          code: codeToExchange,
          correlative: pending.id,
          captcha: options.captcha,
        },
      },
    });
  }, [options.captcha, exchange]);

  return { ...result, code, checked };
}
