import { redirect, LoaderFunctionArgs, LoaderFunction } from 'react-router-dom';
import { storeQrCode, pendingQrCode } from '@hks/qr';
import { profileUpdated } from './protected';

export const processQrCode = ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const code = url.searchParams.get('cod');
  const id = url.searchParams.get('id');

  if (!code || !id) {
    return redirect('/qr-canjeado?valid=0');
  }

  storeQrCode({
    id,
    code,
    url: request.url,
  });

  return redirect('/procesar-qr');
};

export const hasPendingQrCodes = async () => {
  const redirectTo = await profileUpdated();
  if (redirectTo) {
    return redirectTo;
  }

  const pending = pendingQrCode();
  if (!pending) {
    return redirect('/home');
  }

  return null;
};

export const ifNoPendingQrCode = (cb: (props?: LoaderFunctionArgs) => ReturnType<LoaderFunction>) => {
  return (props: LoaderFunctionArgs): ReturnType<LoaderFunction> => {
    const pending = pendingQrCode();
    if (pending) {
      return redirect('/procesar-qr');
    }

    return cb(props);
  };
};
