export type Job<T = unknown> = () => Promise<T>;

export type TypedJob = {
  async?: Job;
  sync?: Job;
};

export type Buff = TypedJob[];

export class Queue {
  private collecting = false;

  private buffer: Buff;

  constructor(buffer: Buff = []) {
    this.buffer = buffer;
  }

  pushAsync<T = unknown>(cb: Job<T>): Promise<T> {
    return new Promise((f, r) => {
      this.buffer.push({
        async: () => cb().then(f).catch(r),
      });

      this.dequeue();
    });
  }

  pushSync<T = unknown>(cb: Job<T>): Promise<T> {
    return new Promise((f, r) => {
      this.buffer.push({
        sync: () => cb().then(f).catch(r),
      });

      this.dequeue();
    });
  }

  async dequeue() {
    if (this.collecting) {
      return;
    }

    this.collecting = true;
    await this.dispatch();
    this.collecting = false;
  }

  async dispatch(): Promise<unknown> {
    const next = this.buffer.shift();
    if (!next) {
      return;
    }

    if (next.async) {
      next.async();
      return await this.dispatch();
    }

    if (next.sync) {
      await next.sync();
      return await this.dispatch();
    }

    throw new Error("The given job doesn't have a 'async' or 'sync' property");
  }
}
