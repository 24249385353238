import { Box, BoxProps, Text, Heading, Button, Image } from '@chakra-ui/react';
import LogoPrimax from '@cmp/img/newlogo.png';
import { useNavigate } from 'react-router-dom';

export interface ErrorPageProps extends BoxProps {
  title: string;
  description: string;
  summary: string;
  backButton?: {
    text?: string | null;
    redirectTo?: string | null;
  } | null;
  retryButton?: {
    text?: string | null;
    redirectTo?: string | null;
  } | null;
}

export function ErrorPage({ title, description, summary, backButton, retryButton, ...props }: ErrorPageProps) {
  const navigate = useNavigate();

  const retryAction = () => {
    if (retryButton?.redirectTo) {
      navigate(retryButton.redirectTo);
      return;
    }

    navigate(0);
  };

  return (
    <Box
      minH="100vh"
      pb="30px"
      display={'flex'}
      flexDir={'column'}
      justifyContent={'space-between'}
      alignContent={''}
      px={{ base: '10%', lg: '20%' }}
      {...props}
    >
      <Box mx="auto">
        <Image height={'50px'} src={LogoPrimax} alt="primax" />
      </Box>
      <Box w="full" maxW="525px" textAlign={'left'}>
        <Heading fontSize={{ base: '33px', lg: '40px' }} textColor={'grises.1000'}>
          {title}
        </Heading>
        <Text fontSize={'lg'} mt="24px" fontWeight={'400'} lineHeight={'28px'}>
          {description}
        </Text>
        <Text color={'orange'} mt="48px" fontSize={'lg'}>
          {summary}
        </Text>
      </Box>
      <Box w="full">
        <Box display={{ base: 'block', lg: 'flex' }} justifyContent={'flex-end'}>
          <Button
            w={{ base: '300px', lg: '214px' }}
            variant={'outline'}
            colorScheme="blue"
            mx="12px"
            mt={{ base: '20px', md: '10px', lg: '0px' }}
            _active={{}}
            onClick={() => navigate(backButton?.redirectTo || '/')}
          >
            {backButton?.text || 'Salir'}
          </Button>
          <Button
            mx="12px"
            onClick={retryAction}
            w={{ base: '300px', lg: '214px' }}
            mt={{ base: '20px', md: '10px', lg: '0px' }}
            colorScheme="blue"
          >
            {retryButton?.text || 'Intentar de nuevo'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
