import { Outlet } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { AuthProvider } from '@lib/auth';
import { UserProvider } from '@lib/userProvider';
import theme from '@cmp/theme';
import Fonts from '@cmp/fonts';
import { useNavigation } from 'react-router-dom';
import Loader from '@cmp/loader';
import { ButtonWhatssap } from '@cmp/buttons/buttonWhatssap';
import { WpProvider } from '@cmp/buttons/buttonWhatsappProvider';

import '@/App.css';

export const App: React.FC = () => {
  const navigation = useNavigation();
  const isLoading = navigation.state === 'loading';

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <AuthProvider>
        <UserProvider>
          <WpProvider>
            <Outlet />

            {!isLoading && (
              <ButtonWhatssap
                zIndex={2}
                position={'fixed'}
                right={{ base: '24px', lg: '60px' }}
                // 4vh is the 'Registrar comprobante' buttom space between the bottom.
                // 74h: 54px is the 'Registrar comprobante' height and 20px additional.
                bottom={{ base: 'calc(4vh + 74px)', lg: '10%' }}
              />
            )}

            {isLoading && <Loader m={0} zIndex={5} position={'fixed'} top={0} left={0} w={'100vw'} h={'100vh'} />}
          </WpProvider>
        </UserProvider>
      </AuthProvider>
    </ChakraProvider>
  );
};
